require("mousetrap")

$(document).on('turbolinks:load', function() {
    $("[accesskey]").each(function() {
        var currentAnchor = $(this)

        Mousetrap.bind(
            this.accessKey,
            function() {
                currentAnchor[0].click()
            });
    });
});